// src/components/Services.js
import React from 'react';
import './services.css';

const Services = () => (
    <section id="services">
        <h2>Our Services</h2>
        <div className="service-list">
            <div className="service-item">
                <h3>App Development</h3>
                <p>We develop mobile applications for both iOS and Android platforms. Our team ensures that your app is high-performing, feature-rich, and provides an intuitive user experience.</p>
            </div>
            <div className="service-item">
                <h3>Front-End Development</h3>
                <p>We create responsive and dynamic front-end solutions using the latest technologies . Our designs are not only visually appealing but also provide an excellent user experience.</p>
            </div>
            <div className="service-item">
                <h3>Back-End Development</h3>
                <p>Our back-end services include server-side development, database management, and API integration. We ensure your applications are secure and scalable.</p>
            </div>
            <div className="service-item">
                <h3>Web Design</h3>
                <p>Our design team creates stunning and user-friendly website designs that reflect your brand identity. We focus on UX/UI principles to ensure your website is not only beautiful but also easy to navigate.</p>
            </div>
            <div className="service-item">
                <h3>App Store Publishing & Deployment</h3>
                <p>We manage the complete app publishing process for both iOS and Android platforms. Our team handles everything from app store submissions to compliance checks, ensuring a smooth launch and hassle-free experience for our clients.</p>
            </div>
            <div className="service-item">
                <h3>Website Publishing & Deployment</h3>
                <p>We take care of the entire website publishing and deployment process. From setting up your domain to configuring hosting and ensuring your site is live and optimized, we handle all the technical details so you don’t have to worry about a thing.</p>
            </div>
            <div className="service-item">
                <h3>Maintenance & Support</h3>
                <p>We offer ongoing maintenance and support services to ensure your websites and applications run smoothly. Our team is available to address any issues, perform updates, and keep your systems secure.</p>
            </div>
        </div>
    </section>
);

export default Services;
