// src/components/Header.js
import React from 'react';
import './header.css';
import logo from '../logo.png'; // Adjust the path as necessary

const Header = () => (
  <header>
    <div className="logo-container">
      <img src={logo} alt="Company Logo" className="logo" />
      <h1>Dahab Tech</h1>
    </div>
    <nav>
      <a href="#services">Services</a>
      <a href="#projects">Projects</a>
    </nav>
  </header>
);

export default Header;
