// src/components/Vision.js
import React from 'react';
import './vision.css';

const Vision = () => (
  <section id="vision">
    <div className="vision-content">
      <h2>Bring Your Dreams to Life</h2>
      <p>At Dahab Tech, we believe in turning your vision into reality. Whether you’re looking to develop a cutting-edge website, create a powerful mobile app, or enhance your online presence, we are here to bring your ideas to life. Contact us today to discuss your needs and receive a personalized offer tailored to your goals.</p>
      <a href="mailto:dahab-tech@dahab-tech.com" className="cta-button">Get in Touch</a>
    </div>
  </section>
);

export default Vision;
