// src/App.js
import React from 'react';
import Header from './components/Header';
import Services from './components/Services';
import Projects from './components/Projects';
import Footer from './components/Footer';
import './App.css';
import Vision from './components/Vision';

const App = () => (
  <div>
    <Header />
    <main>
      <Vision />
      <Services />
      <Projects />
    </main>
    <Footer />
  </div>
);

export default App;
