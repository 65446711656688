// src/components/Footer.js
import React from 'react';

const Footer = () => (
  <footer>
    <p>&copy; 2024 Dahab Tech, LLC. All rights reserved.</p>
    <p>Contact us at: <a style={{ color: 'white' }} href="dahab-tech@dahab-tech.com">dahab-tech@dahab-tech.com</a></p>
  </footer>
);

export default Footer;
