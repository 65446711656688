// src/components/Projects.js
import React from 'react';
import './projects.css';
import FBLogo from '../Pics/FBLogo.png'
import DDLogo from '../Pics/DDLogo.png'

const Projects = () => (
    <section id="projects">
        <h2>Our Projects</h2>
        <div className="project-list">
            <div onClick={() => window.open('https://fitbuddy.fit', '_blank')} className="project-item">
                <img src={FBLogo} alt="Project 2" />
                <h3>Project 1: Fit-Buddy App, Social-Sport App</h3>
                <p>We created a dynamic sports app that connects users who want to workout together. Features include group workout sessions, access to certified coaches, chat functionality, and the ability to share posts and updates. This app aims to foster a community of fitness enthusiasts, making it easier to find workout buddies and stay motivated.</p>
            </div>
            {/*<div onClick={() => window.open('https://dahabdesign.com', '_blank')} className="project-item">
                <img src={DDLogo} alt="Project 1" />
                <h3>Project 2: Dahab Design, Company Website</h3>
                <p>We developed a comprehensive website for a company specializing in construction, architecture, and furniture. This project involved integrating all these branches with their social media platforms, allowing seamless updates across their website and three social media pages without any additional effort.</p>
            </div>*/}
        </div>
    </section>
);

export default Projects;
